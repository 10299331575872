var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17;
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import { gql } from "@apollo/client";
export var ADD_USER_MUTATION = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  mutation AddUsers($organisationId: String!, $users: [AddUser!]!) {\n    addUsers(organisationId: $organisationId, users: $users) {\n      userId\n      displayName\n      email\n      profileUrl\n      uniqueUrl\n      status\n      teams {\n        team {\n          teamId\n          teamName\n        }\n        role {\n          roleId\n          roleName\n        }\n      }\n      employeeId\n    }\n  }\n"])));
export var EDIT_USER_MUTATION = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  mutation EditUser($organisationId: String!, $userId: String!, $user: AddUser!) {\n    editUser(organisationId: $organisationId, userId: $userId, user: $user) {\n      userId\n      displayName\n      email\n      profileUrl\n      uniqueUrl\n      status\n      teams {\n        team {\n          teamId\n          teamName\n        }\n        role {\n          roleId\n          roleName\n        }\n      }\n      employeeId\n    }\n  }\n"])));
export var DELETE_USER_MUTATION = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  mutation DeleteUser($organisationId: String!, $userId: String!) {\n    deleteUser(organisationId: $organisationId, userId: $userId)\n  }\n"])));
export var ORGANISATION_USERS_QUERY = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  query OrganisationUsers(\n    $organisationId: String!\n    $teamId: String!\n    $userRole: String!\n    $limit: Int\n    $offset: Int\n    $searchText: String\n  ) {\n    organisationUsers(\n      organisationId: $organisationId\n      teamId: $teamId\n      userRole: $userRole\n      limit: $limit\n      offset: $offset\n      searchText: $searchText\n    ) {\n      userId\n      displayName\n      email\n      profileUrl\n      uniqueUrl\n      status\n      passwordStatus\n      teams {\n        team {\n          teamId\n          teamName\n        }\n        role {\n          roleId\n          roleName\n        }\n      }\n      organisationCreatedBy\n      appVersion\n      billableRate\n      appIcon\n      labourCost\n\n      billableRateType\n      labourCostType\n      employeeId\n      userRole\n    }\n  }\n"])));
export var ORGANISATION_USERS_COUNT_QUERY = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  query OrganisationUsersCount($organisationId: String!, $teamId: String!, $userRole: String!) {\n    organisationUsersCount(organisationId: $organisationId, teamId: $teamId, userRole: $userRole) {\n      userCount\n    }\n  }\n"])));
export var ORGANISATION_CURRENCY_SETTING_QUERY = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  query getOrganisationCurrencies($organisationId: String!) {\n    getOrganisationCurrencies(organisationId: $organisationId) {\n      currencies\n    }\n  }\n"])));
export var USER_ROLES_QUERY = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  query UserRoles($organisationId: String!) {\n    userRoles(organisationId: $organisationId) {\n      roleId\n      roleName\n    }\n  }\n"])));
export var ORGANISATION_TEAMS_QUERY = gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  query OrganisationTeams($organisationId: String!) {\n    organisationTeams(organisationId: $organisationId) {\n      teamName\n      teamId\n    }\n  }\n"])));
export var GENERATE_INVITE_LINK_QUERY = gql(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  mutation GenerateInviteLink($teamId: String!, $userId: String!) {\n    generateInviteLink(teamId: $teamId, userId: $userId)\n  }\n"])));
export var GET_INVITE_LINK_HISTORY_QUERY = gql(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  query GetInviteLinkHistory($organisationId: String!) {\n    getInviteLinkHistory(organisationId: $organisationId) {\n      teamId\n      inviteToken\n      tokenExpireTime\n      teamName\n    }\n  }\n"])));
export var RESENT_INVITE_LINK_MUTATION = gql(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  mutation ResentInviteLink($userId: String!, $organisationId: String!) {\n    resentInviteLink(userId: $userId, organisationId: $organisationId)\n  }\n"])));
export var USER_WORK_DAY_SETTINGS_QUERY = gql(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  query userWorkDaySettings($organisationId: String!, $userId: String!, $timeZone: String!) {\n    userWorkDaySettings(organisationId: $organisationId, userId: $userId, timeZone: $timeZone) {\n      userWorkSettings {\n        screenCapture\n        blurScreenCapture\n        screenCaptureInterval\n        allowMemberToAccessScreenCapture\n        allowMemberToDeleteScreenCapture\n        allowMemberToAccessTimesheet\n        allowMemberToAccessTimelapseVideo\n        idleMinutesThreshold\n        stealthMode\n        expectedClockInTime\n        trackAppsAndWebsites\n        organisationTimezone\n        allowHeadToAccessTheirTeamMemberScreenCaptureAndTimelapseVideo\n\n        autoClockIn\n      }\n      user {\n        userId\n        displayName\n      }\n      pageComponent\n      showStealthMode\n    }\n  }\n"])));
export var ADD_USER_WORK_DAY_SETTINGS_MUTATION = gql(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  mutation addUserWorkDaySettings(\n    $screenCapture: String\n    $blurScreenCapture: String\n    $screenCaptureInterval: String\n    $allowMemberToAccessScreenCapture: String\n    $allowMemberToDeleteScreenCapture: String\n    $allowMemberToAccessTimesheet: String\n    $allowMemberToAccessTimelapseVideo: String\n    $idleMinutesThreshold: String\n    $stealthMode: String\n    $expectedClockInTime: String\n    $organisationId: String!\n    $userId: String!\n    $timeZone: String!\n    $trackAppsAndWebsites: String\n    $organisationTimezone: String\n    $allowHeadToAccessTheirTeamMemberScreenCaptureAndTimelapseVideo: String\n    $autoClockIn: String\n  ) {\n    addUserWorkDaySettings(\n      screenCapture: $screenCapture\n      blurScreenCapture: $blurScreenCapture\n      screenCaptureInterval: $screenCaptureInterval\n      allowMemberToAccessScreenCapture: $allowMemberToAccessScreenCapture\n      allowMemberToDeleteScreenCapture: $allowMemberToDeleteScreenCapture\n      allowMemberToAccessTimesheet: $allowMemberToAccessTimesheet\n      allowMemberToAccessTimelapseVideo: $allowMemberToAccessTimelapseVideo\n      idleMinutesThreshold: $idleMinutesThreshold\n      stealthMode: $stealthMode\n      expectedClockInTime: $expectedClockInTime\n      organisationId: $organisationId\n      userId: $userId\n      timeZone: $timeZone\n      trackAppsAndWebsites: $trackAppsAndWebsites\n      organisationTimezone: $organisationTimezone\n      allowHeadToAccessTheirTeamMemberScreenCaptureAndTimelapseVideo: $allowHeadToAccessTheirTeamMemberScreenCaptureAndTimelapseVideo\n\n      autoClockIn: $autoClockIn\n    )\n  }\n"])));
export var UPDATE_NEW_EMPLOYEE_TRANSACTION_DEATILS_MUTATION = gql(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  mutation UpdateNewEmployeeTransactionDeatils(\n    $organisationId: String!\n    $planId: String!\n    $userId: String!\n    $userCount: Int!\n  ) {\n    updateNewEmployeeTransactionDeatils(\n      organisationId: $organisationId\n      planId: $planId\n      userId: $userId\n      userCount: $userCount\n    )\n  }\n"])));
export var GET_PAID_AND_UNUSED_USER_COUNT_QUERY = gql(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  query GetPaidAndUnusedUserCount($organisationId: String!) {\n    getPaidAndUnusedUserCount(organisationId: $organisationId) {\n      userCount\n      paidEmailId\n    }\n  }\n"])));
export var ADD_BILLABLE_RATE_MUTATION = gql(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n  mutation addBillableRateForUsers(\n    $userId: String!\n    $amount: Int!\n    $billableTime: String!\n    $startDate: Float\n    $organisationId: String!\n    $endDate: Float\n    $timezone: String!\n  ) {\n    addBillableRateForUsers(\n      userId: $userId\n      amount: $amount\n      billableTime: $billableTime\n      startDate: $startDate\n      organisationId: $organisationId\n      endDate: $endDate\n      timezone: $timezone\n    ) {\n      userId\n      displayName\n      email\n      profileUrl\n      uniqueUrl\n      status\n      billableRate\n      labourCost\n      billableRateType\n      labourCostType\n    }\n  }\n"])));
export var ADD_LABOUR_COST_MUTATION = gql(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n  mutation addLabourCostForUsers(\n    $userId: String!\n    $amount: Int!\n    $labourTime: String!\n    $startDate: Float\n    $organisationId: String!\n    $endDate: Float\n    $timezone: String!\n  ) {\n    addLabourCostForUsers(\n      userId: $userId\n      amount: $amount\n      labourTime: $labourTime\n      startDate: $startDate\n      organisationId: $organisationId\n      endDate: $endDate\n      timezone: $timezone\n    ) {\n      userId\n      displayName\n      email\n      profileUrl\n      uniqueUrl\n      status\n      billableRate\n      labourCost\n      billableRateType\n      labourCostType\n    }\n  }\n"])));